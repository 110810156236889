var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tSampleList" },
    [
      _c(
        "Card",
        { staticClass: "d-card" },
        [
          _c(
            "Row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openSearch,
                  expression: "openSearch",
                },
              ],
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleSearch.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "Form",
                {
                  ref: "searchForm",
                  attrs: {
                    model: _vm.searchForm,
                    inline: "",
                    "label-width": 70,
                  },
                },
                [
                  _c(
                    "Form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 150,
                            expression: "150",
                          },
                        ],
                        attrs: { type: "text", placeholder: "请输入名称" },
                        model: {
                          value: _vm.searchForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "name", $$v)
                          },
                          expression: "searchForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "Form-item",
                    { attrs: { label: "标本代码" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 150,
                            expression: "150",
                          },
                        ],
                        attrs: { type: "text", placeholder: "请输入标本代码" },
                        model: {
                          value: _vm.searchForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "code", $$v)
                          },
                          expression: "searchForm.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "Form-item",
                    { attrs: { label: "关联码" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 150,
                            expression: "150",
                          },
                        ],
                        attrs: { type: "text", placeholder: "请输入关联码" },
                        model: {
                          value: _vm.searchForm.liscode,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "liscode", $$v)
                          },
                          expression: "searchForm.liscode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "Form-item",
                    {
                      staticClass: "br",
                      staticStyle: { "margin-left": "-35px" },
                    },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", icon: "ios-search" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c("Button", { on: { click: _vm.handleReset } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "operation" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "primary", icon: "md-add" },
                  on: { click: _vm.addTSample },
                },
                [_vm._v("新增")]
              ),
              _c(
                "Dropdown",
                { on: { "on-click": _vm.handleDropdown } },
                [
                  _c(
                    "Button",
                    [
                      _vm._v(" 更多操作 "),
                      _c("Icon", { attrs: { type: "md-arrow-dropdown" } }),
                    ],
                    1
                  ),
                  _c(
                    "DropdownMenu",
                    { attrs: { slot: "list" }, slot: "list" },
                    [
                      _c(
                        "DropdownItem",
                        { attrs: { name: "refresh" } },
                        [
                          _c("Icon", { attrs: { type: "md-sync" } }),
                          _vm._v(" 刷新 "),
                        ],
                        1
                      ),
                      _c(
                        "DropdownItem",
                        { attrs: { name: "removeAll" } },
                        [
                          _c("Icon", { attrs: { type: "md-trash" } }),
                          _vm._v(" 批量删除 "),
                        ],
                        1
                      ),
                      _c(
                        "DropdownItem",
                        { attrs: { name: "export" } },
                        [
                          _c("Icon", { attrs: { type: "md-arrow-down" } }),
                          _vm._v(" 导出本页数据 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function ($event) {
                      _vm.openSearch = !_vm.openSearch
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.openSearch ? "关闭搜索" : "开启搜索"))]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function ($event) {
                      _vm.openTip = !_vm.openTip
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.openTip ? "关闭提示" : "开启提示"))]
              ),
              _c(
                "div",
                { staticStyle: { float: "right" } },
                [
                  _c(
                    "Poptip",
                    {
                      attrs: {
                        transfer: "",
                        trigger: "click",
                        placement: "bottom-end",
                        title: "动态列",
                      },
                    },
                    [
                      _c("Button", { attrs: { icon: "md-list" } }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            "min-height": "5vh",
                          },
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [
                          _c(
                            "Checkbox-group",
                            {
                              on: { "on-change": _vm.checkboxChange },
                              model: {
                                value: _vm.colOptions,
                                callback: function ($$v) {
                                  _vm.colOptions = $$v
                                },
                                expression: "colOptions",
                              },
                            },
                            _vm._l(_vm.colSelect, function (item, i) {
                              return _c(
                                "row",
                                { key: i },
                                [
                                  _c("checkbox", {
                                    staticStyle: {
                                      "margin-bottom": "5px",
                                      "font-weight": "700",
                                    },
                                    attrs: { label: item },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openTip,
                  expression: "openTip",
                },
              ],
            },
            [
              _c("Alert", { attrs: { "show-icon": "" } }, [
                _vm._v(" 已选择 "),
                _c("span", { staticClass: "select-count" }, [
                  _vm._v(_vm._s(_vm.selectCount)),
                ]),
                _vm._v(" 项 "),
                _c(
                  "a",
                  {
                    staticClass: "select-clear",
                    on: { click: _vm.clearSelectAll },
                  },
                  [_vm._v("清空")]
                ),
                _c("span", { staticStyle: { float: "right" } }, [
                  _vm._v("点击右上角按钮配置动态列↑"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Table", {
                ref: "table",
                attrs: {
                  "max-height": _vm.height,
                  loading: _vm.loading,
                  border: "",
                  columns: _vm.columns,
                  sortable: "custom",
                  data: _vm.data,
                },
                on: {
                  "on-sort-change": _vm.changeSort,
                  "on-selection-change": _vm.showSelect,
                },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function ({ row, index }) {
                      return [
                        _c(
                          "Button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleLook(row, index)
                              },
                            },
                          },
                          [
                            _c("Icon", { attrs: { type: "ios-eye-outline" } }),
                            _vm._v(" 查看 "),
                          ],
                          1
                        ),
                        _c(
                          "Dropdown",
                          { attrs: { transfer: true } },
                          [
                            _c(
                              "Button",
                              { attrs: { size: "small" } },
                              [
                                _vm._v(" 更多操作 "),
                                _c("Icon", {
                                  attrs: { type: "md-arrow-dropdown" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "DropdownMenu",
                              { attrs: { slot: "list" }, slot: "list" },
                              [
                                _c(
                                  "DropdownItem",
                                  {
                                    attrs: { name: "edit" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleEdit(row, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      attrs: { type: "ios-create-outline" },
                                    }),
                                    _vm._v(" 修改 "),
                                  ],
                                  1
                                ),
                                _c(
                                  "DropdownItem",
                                  {
                                    attrs: { name: "delete" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.deleteData(row, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", { attrs: { type: "md-trash" } }),
                                    _vm._v(" 删除 "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "Row",
            { staticClass: "page", attrs: { type: "flex", justify: "end" } },
            [
              _c("Page", {
                attrs: {
                  current: _vm.searchForm.pageNumber,
                  total: _vm.total,
                  "page-size": _vm.searchForm.pageSize,
                  "page-size-opts": [10, 20, 50],
                  size: "small",
                  "show-total": "",
                  "show-elevator": "",
                  "show-sizer": "",
                },
                on: {
                  "on-change": _vm.changePage,
                  "on-page-size-change": _vm.changePageSize,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("updateTSample", {
        attrs: { TSampleId: _vm.TSampleId, modalTitle: _vm.title },
        on: { handleSearch: _vm.handleSearch },
        model: {
          value: _vm.updateShow,
          callback: function ($$v) {
            _vm.updateShow = $$v
          },
          expression: "updateShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }